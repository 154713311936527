type Files = {
    fileName: string;
    type: string;
    slug?: string;
  };

  const dataTipsAndTrik: Array<Files> = [
    {
      fileName: "Deret Angka",
      type: "Video",
      slug: "deret-angka",
    },
    {
      fileName: "Deret Huruf",
      type: "Video",
      slug: "deret-huruf",
    },
    {
      fileName: "Himpunan",
      type: "Video",
      slug: "himpunan",
    },
    {
      fileName: "Akumulasi Pekerjaan",
      type: "Video",
      slug: "akumulasi-pekerjaan",
    },
    {
      fileName: "PERBANDINGAN PRODUKSI",
      type: "Video",
      slug: "perbandingan-produksi",
    },
    {
      fileName: "MAGIC OF X/6",
      type: "Video",
      slug: "magic-of-x6",
    },
    {
      fileName: "MAGIC OF X/8",
      type: "Video",
      slug: "magic-of-x8",
    },
    {
      fileName: "MENGETAHUI PECAHAN YANG LEBIH BESAR",
      type: "Video",
      slug: "mengetahui-pecahan-yang-lebih-besar",
    },
    {
      fileName: "SOAL RUMIT PDKT AJA",
      type: "Video",
      slug: "soal-rumit-pdkt-aja",
    },
    {
      fileName: "MENGHITUNG CEPAT EKOR KEPALA",
      type: "Video",
      slug: "menghitung-cepat-ekor-kepala",
    },
    {
      fileName: "NUMERIK DAN PERBANDINGAN",
      type: "Video",
      slug: "numerik-dan-perbandingan",
    },
    {
      fileName: "Himpunan",
      type: "Video",
      slug: "himpunan",
    },
    {
      fileName: "Akumulasi Pekerjaan",
      type: "Video",
      slug: "akumulasi-pekerjaan",
    },
    {
      fileName: "PERBANDINGAN PRODUKSI",
      type: "Video",
      slug: "perbandingan-produksi",
    },
    {
      fileName: "MAGIC OF X/6",
      type: "Video",
      slug: "magic-of-x6",
    },
    {
      fileName: "MAGIC OF X/8",
      type: "Video",
      slug: "magic-of-x8",
    },
    {
      fileName: "MENGETAHUI PECAHAN YANG LEBIH BESAR",
      type: "Video",
      slug: "mengetahui-pecahan-yang-lebih-besar",
    },
    {
      fileName: "SOAL RUMIT PDKT AJA",
      type: "Video",
      slug: "soal-rumit-pdkt-aja",
    },
    {
      fileName: "MENGHITUNG CEPAT EKOR KEPALA",
      type: "Video",
      slug: "menghitung-cepat-ekor-kepala",
    },
    {
      fileName: "NUMERIK DAN PERBANDINGAN",
      type: "Video",
      slug: "numerik-dan-perbandingan",
    },
  ];

  const videosMT = (): Array<Files> => {
    return dataTipsAndTrik.filter((e) => {
      if (e.slug === undefined) {
        const a = e;
        a.slug = "this-is-slug";
        return a;
      } else {
        return e;
      }
    });
  };

  export { Files, videosMT };
