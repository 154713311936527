
import { defineComponent, ref, provide } from "vue";
import { Files, videosMT } from "./tutorial";

export default defineComponent({
  components: {},
  setup() {
    const salesReportFilter = ref("");
    const importantNotesRef = ref<HTMLElement>();
    let videos: Array<Files> = [];

    provide("bind[importantNotesRef]", (el: HTMLElement) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("next");
    };
    const setVideos = () => {
      videos = videosMT();
    };
    const setTLS = (data: Files) => {
      window.localStorage.setItem(
        `${data.type}-${data.slug}`,
        JSON.stringify(data)
      );
    };

    setVideos();

    return {
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      videos,
      setTLS,
    };
  },
});
